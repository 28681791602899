
import { ApiOrdersPaymentRequestPostRequest, OrderPaymentDto, CustomerPaymentMethodsDto, CustomerPaymentMethodDto, ApiCustomerPaymentMethodGetRequest, PagedResultOfCustomerOrdersDto } from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import { PropType } from "vue/types/v3-component-props";
import { formatDate } from "@/utils/formatDate";
import DialogBox from "@/components/DialogBox.vue";
import Pagination from "@/components/Pagination.vue";
import AllOrders from "@/components/Orders/AllOrders.vue";
import { customersPaymentMethodPost, customersIdOrdersGet } from "@/api/customers";

@Component({
  name: "PaymentsMethods",
  components: { DialogBox, Pagination, AllOrders },
  props: {
    data: { required: true, type: Array as PropType<CustomerPaymentMethodsDto[]> },
    customerId: { required: true, type: String }
  }
})
export default class extends Vue {
  methodDialogVisible = false;
  paymentMethodTableData: Array<CustomerPaymentMethodDto> = [];
  newPaymentMethod: ApiCustomerPaymentMethodGetRequest = { orderId: '' };
  ordersData: PagedResultOfCustomerOrdersDto = {
    totalCount: 0,
    pageCount: 0,
    pageSize: 0,
    pageNumber: 0,
    data: [],
  };
  // pageSize = 20;
  // pageNumber = 1;

  // handlePageSizeChange(size: number) {
  //   this.pageNumber = 1;
  //   this.pageSize = size;
  //   this.$emit('pageChange', this.pageNumber, this.pageSize);
  // }

  // handlePageNumberClick(page: any) {
  //   this.pageNumber = page;
  //   this.$emit('pageChange', this.pageNumber, this.pageSize);
  // }

  async createPaymentMethod() {
    if (this.newPaymentMethod != null) {
      await customersPaymentMethodPost(this.newPaymentMethod).then(() => {
        this.dialogClose()
      }).catch(() => {
        this.dialogClose()
      })
    }
    this.dialogClose()
  }

  handleDate(date: any) {
    return formatDate(date);
  }

  dialogOpen() {
    this.methodDialogVisible = true;
    this.loadOrders();
  }

  dialogClose() {
    this.methodDialogVisible = false;
  }

  orderSelect(id: any = undefined) {
    if (id) {
      this.newPaymentMethod = { orderId: id[0] };
    }
    else {
      this.newPaymentMethod = { orderId: '' };
    }
  }

  async loadOrders(pageNumber: number = 1, pageSize: number = 8) {
    this.orderSelect();
    this.ordersData = await customersIdOrdersGet(this.$props.customerId, pageNumber || this.ordersData.pageNumber, pageSize || this.ordersData.pageSize);
  }
}


import { CustomerType, IDType, Gender, OrderMessagesDto, ApiOrdersActivateOrderPutRequest, PagedResultOfOrderPaymentViewModel, QueueStatus, PagedResultOfCustomerOrdersDto, CustomersDto, PagedResultOfAuditLogDto, AuditTypes } from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import Account from "@/components/Orders/Account.vue";
import Products from "@/components/Orders/Products.vue";
import TopUps from "@/components/Orders/TopUps.vue";
import OtherProducts from "@/components/Orders/OtherProducts.vue";
import Notifications from "@/components/Orders/Notifications.vue";
import AllOrders from "@/components/Orders/AllOrders.vue";
import Payments from "@/components/Orders/Payments.vue";
import PaymentsMethods from "@/components/Orders/PaymentsMethods.vue";
import AuditLogs from "@/components/Orders/AuditLogs.vue";
import { ordersActivate, ordersBypassTelkomSuspend } from "@/api/orders";
import DialogBox from "@/components/DialogBox.vue";
import { hasClaim } from "@/utils/claims";
import { PropType } from "vue/types/v3-component-props";
import store from "@/store";
import { customersGetId, customersIdOrdersGet } from "@/api/customers";
import { userActivityCustomerIdGet } from "@/api/user-activity";

type Tab = "account" | "activity-logs" | "orders";
type ActionName = "activate" | "suspend";
type ActionDetail = {
  callback: Function;
  message: string;
  title: string;
};

@Component({
  name: "CustomerDetail",
  components: { Account, Products, Notifications, Payments, PaymentsMethods, DialogBox, TopUps, OtherProducts, AllOrders, AuditLogs },
  props: {
    id: { required: true, type: String },
    filter: { type: String },
    pageNumber: { type: String },
    pageSize: { type: String },
    queueStatus: { type: String as PropType<QueueStatus> },
    billingDayOfMonth: { type: String },
  },
})
export default class extends Vue {
  tabData: CustomersDto = {
    dateTimeStamp: "",
    cancelledDate: "",
    firstName: "",
    middleName: "",
    lastName: "",
    idNumber: "",
    idNationality: "",
    emailAddress: "",
    mobileNumber: "",
    additionalNumber: "",
    status: "",
    statusReason: "",
    dateOfBirth: "",
    suspendedDate: "",
    gender: Gender.Male,
    idType: IDType.IdNumber,
    customerType: CustomerType.Customer,
    usersId: "",
    isAccountOtpValidated: true,
    id: "",
    customerPaymentMethods: [],
    // TODO: make sure whether this new property needs to be displayed
    orders: [],
    address: {
      // TODO: make sure whether this new property is relevant
      id: "",
      line1: "",
      line2: "",
      line3: "",
      suburb: "",
      city: "",
      province: "",
      country: "",
      postalCode: "",
      // TODO: make sure whether this new property is relevant
      dateTimeStamp: "",
    }
  };
  paymentsTabData: PagedResultOfOrderPaymentViewModel = {
    totalCount: 0,
    pageCount: 0,
    pageSize: 0,
    pageNumber: 0,
    data: [],
  };
  notificationTabData: OrderMessagesDto[] = [];
  ordersTabData: PagedResultOfCustomerOrdersDto = {
    totalCount: 0,
    pageCount: 0,
    pageSize: 0,
    pageNumber: 0,
    data: [],
  };
  activityTabData: PagedResultOfAuditLogDto = {
    totalCount: 0,
    pageCount: 0,
    pageSize: 0,
    pageNumber: 0,
    data: [],
  };
  activeTab: Tab = "account";
  title = "";
  from: "Search" | "PaymentsDue" = "Search";
  dialogVisible: boolean = false;
  dialogSelectedAction: ActionName | null = null;
  userHasPermisson = false;
  activationDate: string | null = null;
  activateDateError = false;
  actions: Record<ActionName, ActionDetail> = {
    activate: {
      callback: () => this.activateOrder(),
      message: "Have you checked the Telkom OMS system to ensure the user has been activated by Telkom?",
      title: "Confirm Order Activation",
    },
    suspend: {
      callback: () => this.bypassTelkomSuspendOrder(),
      message: "Are you sure you want to bypass Telkom and suspend this order?",
      title: "Confirm Order Suspension",
    },
  };

  get queries() {
    return {
      filter: this.$props.filter,
      pageNumber: this.$props.pageNumber,
      queueStatus: this.$props.queueStatus,
      pageSize: this.$props.pageSize,
      billingDayOfMonth: this.$props.billingDayOfMonth,
    };
  }

  get activateConfirmDisabled() {
    return this.activationDate ? false : true;
  }

  async created() {
    if (this.$route.query.from == "payments-due") {
      this.from = "PaymentsDue";
    }

    this.userHasPermisson = await hasClaim("ViewOrders");

    this.loadInitialData();
    this.title = `${this.tabData.firstName} ${this.tabData.lastName}`;

    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab as Tab;
    } else {
      this.activeTab = "account";
    }

    this.$watch("$route", () => {
      if (this.$route.query.tab) {
        this.activeTab = this.$route.query.tab as Tab;
      } else {
        this.activeTab = "account";
      }
    });
  }

  handleTabClick(tab: any) {
    tab.name ? (this.activeTab = tab.name) : (this.activeTab = tab);
    this.$router.replace({
      name: "CustomerDetail",
      params: { id: this.$props.id },
      query: { tab: this.activeTab == "account" ? undefined : this.activeTab },
    });
  }

  handleAction(action: ActionName) {
    this.dialogSelectedAction = action;
    this.dialogVisible = true;
  }

  handleActionClose() {
    this.dialogSelectedAction = null;
    this.dialogVisible = false;
    this.activationDate = null;
    this.activateDateError = false;
  }

  async activateOrder() {
    if (this.activationDate) {
      const payload: ApiOrdersActivateOrderPutRequest = {
        orderId: this.tabData.id,
        activationDate: this.activationDate,
      };
      await ordersActivate(payload).then(() => {
        this.loadInitialData();
      });
      this.handleActionClose();
    } else {
      this.activateDateError = true;
    }
  }

  bypassTelkomSuspendOrder() {
    ordersBypassTelkomSuspend(this.tabData.id).then(() => {
      this.loadInitialData();
    });
    this.handleActionClose();
  }

  async loadInitialData() {
    if (this.$props.id) {
      store.dispatch("misc/setIsLoadingOverride", true);
      await customersGetId(this.$props.id).then(async (res) => {
        this.title = `${res.firstName} ${res.lastName}`;
        this.tabData = res;

        await store.dispatch("user/addRecentlyViewed", {
          id: this.tabData.id,
          displayName: this.title || "",
          type: "Customer",
        });
      });

      await this.loadOrders();
      await this.loadAuditLogs()
      store.dispatch("misc/setIsLoadingOverride", false);
    }
  }

  async loadOrders(pageNumber: number = 1, pageSize: number = 20) {
    this.ordersTabData = await customersIdOrdersGet(this.tabData.id, pageNumber, pageSize);
  }

  async loadAuditLogs(pageNumber:number = 1, pageSize: number = 20, auditLogTypesId: AuditTypes | undefined = undefined,
  filter: string | undefined = undefined,
  sortDirection: 'Asc' | 'Desc' | undefined = undefined) {
    await userActivityCustomerIdGet(pageNumber, pageSize, this.$props.id, auditLogTypesId || undefined, filter || undefined, sortDirection).then(async (res) => {
      this.activityTabData = res;
    });
  }
}
